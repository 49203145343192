import React, {Component} from "react"
import PropTypes from "prop-types"
import GiftCardAmountOption from "./gift_card_amount_option"
import GiftCardCustomAmount from "./gift_card_custom_amount"

class GiftCardAmount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected,
      customAmount: ""
    };

    this.renderCustomAmount = this.renderCustomAmount.bind(this);
  }

  render() {
    return (
      <div className="pricing-form-group">
        <input type="hidden"
               name={this.props.optionFieldName}
               value={this.state.selected} />
        <input type="hidden"
               name={this.props.customFieldName}
               value={this.state.customAmount} />
        {this.props.options.map(this.renderOption.bind(this))}
        {this.renderCustomAmount()}
      </div>
    );
  }

  renderCustomAmount() {
    let checked = !this.state.selected;

    return (
      <React.Fragment>
        <GiftCardCustomAmount checked={checked}
                              selectOption={this.selectOption.bind(this)}
                              changeAmount={this.changeCustomAmount.bind(this)} />
      </React.Fragment>
    )
  }

  renderOption(option) {
    let checked = (this.state.selected == option.id);

    return (
      <React.Fragment key={option.id}>
        <GiftCardAmountOption id={option.id}
                              label={option.name}
                              checked={checked}
                              selectOption={this.selectOption.bind(this, option)} />
      </React.Fragment>
    );
  }

  selectOption(option) {
    let id = option.id;

    if (!id) {
      id = "";
    }

    this.setState({
      selected: id,
      customAmount: ""
    });
  }

  changeCustomAmount(event) {
    let amount = event.target.value.replace("$", "");

    this.setState({
      customAmount: amount
    });
  }
}

GiftCardAmount.propTypes = {
  options: PropTypes.array,
  checked: PropTypes.string,
  optionFieldName: PropTypes.string,
  customFieldName: PropTypes.string
};

export default GiftCardAmount
