import React, {Component} from "react"
import PropTypes from "prop-types"

class GiftCardCustomAmount extends React.Component {
  constructor(props) {
    super(props);
    this.isChecked = this.isChecked.bind(this);
  }

  render() {
    return (
      <div className="input">
        <div className={this.getClasses()}>
          <input className="form-control"
                 placeholder="Custom Amount"
                 type="text"
                 id="gift_card_custom_amount"
                 onChange={this.props.changeAmount}
                 onClick={this.props.selectOption} />
        </div>
      </div>
    );
  }

  getClasses() {
    let classes = "form-group gift_card_custom_amount offer-product__custom-amount ";
    return classes + this.isChecked();
  }

  isChecked() {
    if (this.props.checked) {
      return "offer-product__custom-amount--valid";
    }
  }
}

GiftCardCustomAmount.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  changeAmount: PropTypes.func
};

export default GiftCardCustomAmount
