import React, {Component} from "react"
import PropTypes from "prop-types"

class GiftCardAmountOption extends React.Component {
  constructor(props) {
    super(props);

    this.getClassName = this.getClassName.bind(this);
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <label onClick={this.props.selectOption}>{this.props.label}</label>
      </div>
    );
  }

  getClassName() {
    let className = "radio";

    if (this.props.checked) {
      className += " selected";
    }

    return className;
  }
}

GiftCardAmountOption.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  selectOption: PropTypes.func
};

export default GiftCardAmountOption
